<template>
  <base-dialog v-model="dialog" title="Confirmação de ação" :max-width="max_width">
    <v-row class="mx-1">
      <v-col cols="12">
        <v-row>
          <v-col v-if="!hideDateInput" class="pb-0" :cols="hasBillingDate ? 4 : 6">
            <date-picker :date.sync="date" label="Data da Ação" :allowed-dates="allowedDates" outline></date-picker>
          </v-col>
          <v-col v-if="!hideDateInput && hasBillingDate" class="pb-0" cols="4">
            <date-picker :date.sync="billing_date" label="Data Início Faturamento" :allowed-dates="allowedDates" outline></date-picker>
          </v-col>
          <v-col class="pb-0" :cols="hasBillingDate ? 4 : 6">
            <mf-text-input v-model="contract_link" label="Link do Contrato" :errors-messages="errorMessages.contract_link" outlined></mf-text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12">
            <mf-text-area-input v-model="observation" label="Observação" hide-details />
          </v-col>
        </v-row>
        <v-row v-if="isSale">
          <v-col class="pt-0" cols="4">
            <label class="card-title">Origem da venda</label>
            <v-autocomplete
              v-model="origin"
              label="-- Selecione --"
              :error-messages="errorMessages.origin"
              :items="origins"
              item-text="value"
              item-value="name"
              outlined
            />
          </v-col>
          <v-col class="pt-0" cols="4">
            <label class="card-title">Sub origem da venda</label>
            <v-select
              v-model="sub_origin"
              label="-- Selecione --"
              :error-messages="errorMessages.sub_origin"
              :items="subOrigins"
              outlined
              :disabled="!origin"
            />
          </v-col>
          <v-col cols="4" />
        </v-row>
        <v-row>
          <v-col v-if="validateContractLink" cols="4">
            <v-checkbox v-model="is_ambev_participation" class="mt-0" label="Participação Ambev" />
            <v-checkbox v-model="is_ambev_incentive" label="Incentivo Ambev" />
          </v-col>
          <v-col v-if="is_ambev_incentive" cols="3">
            <mf-number-input
              v-model="ambev_incentive_months"
              label="Quantidade de Meses"
              :error-messages="errorMessages.ambev_incentive_months"
              outlined
            ></mf-number-input>
          </v-col>
          <v-col v-if="is_ambev_incentive" cols="5">
            <mf-text-input
              v-model="ambev_incentive_text"
              label="Observação ambev"
              :errors-messages="errorMessages.ambev_incentive_text"
              outlined
            ></mf-text-input>
          </v-col>
        </v-row>
        <v-row v-if="isDownsell">
          <v-col id="third-section" cols="6">
            <v-autocomplete
              v-model="causeSelected"
              label="Selecione a Causa do Churn"
              :items="listCauses"
              outlined
              :error-messages="errorMessages.causeSelected"
            />
          </v-col>
          <v-col id="fourth-section" cols="6">
            <mf-text-input disabled :value="reason" label="Motivo do churn" />
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <mf-action-buttons
            class="mr-1"
            :primary-button="{
              text: 'Confirmar',
              action: save,
              isVisible: true,
              isLoading: disableButton
            }"
            :extra-button="{
              text: 'Cancelar',
              action: closeDialog,
              isVisible: true,
              isDisabled: disableButton
            }"
          />
        </v-row>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import BaseDialog from '../atoms/BaseDialog.vue'
import DatePicker from '../../calendar/DatePicker.vue'
import { minValue, required } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'
import { allowedDateCrmActions } from '../../../helpers/crm'
import { QUERY_GET_ORIGINS } from '@/modules/retailers/graphql'
import { churnReasons } from '@/mixin'
import { SUB_ORIGINS_ENUM_MAPPER } from '@/constants'

/**
 * Sales confirmation dialog
 * @component
 */
export default {
  components: {
    BaseDialog,
    DatePicker
  },
  mixins: [churnReasons],
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * @model
     */
    validateContractLink: {
      type: Boolean,
      default: false
    },
    /**
     * Disable button
     */
    disableButton: {
      type: Boolean,
      default: false
    },
    /**
     * Hide the date input
     */
    hideDateInput: {
      type: Boolean,
      default: false
    },
    /**
     * Hide the date input
     */
    hasBillingDate: {
      type: Boolean,
      default: false
    },
    /**
     * Show origins on sale
     */
    isSale: {
      type: Boolean,
      default: false
    },
    /**
     * Show origins on downsell
     */
    isDownsell: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      origins: [],
      origin: '',
      sub_origin: '',
      dialog: this.value,
      max_width: '800',
      date: DateTime.now().toISODate(),
      billing_date: DateTime.now().toISODate(),
      notEmptyRule: v => v.length >= 1 || 'Campo obrigatório',
      contract_link: '',
      is_ambev_incentive: false,
      is_ambev_participation: false,
      ambev_incentive_months: 1,
      ambev_incentive_text: '',
      disable_button: false,
      observation: ''
    }
  },
  computed: {
    subOrigins() {
      if (!this.origin) return []
      return this.origins?.find(origin => origin.name === this.origin)?.sub_origins
    },
    errorMessages() {
      let errors = {}
      const messages = ['Campo obrigatório', 'Valor mínimo de 1']

      if (!this.$v.contract_link.required) errors['contract_link'] = messages[0]
      if (!this.$v.origin.required) errors['origin'] = messages[0]
      if (typeof this.subOrigins === 'object' && this.subOrigins.length !== 0) {
        if (!this.$v.sub_origin?.required) errors['sub_origin'] = messages[0]
      }
      if (this.validateContractLink && this.is_ambev_incentive && !this.$v.ambev_incentive_text.required) errors['ambev_incentive_text'] = messages[0]
      if (this.validateContractLink && this.is_ambev_incentive && !this.$v.ambev_incentive_months?.minValue) errors['ambev_incentive_months'] = messages[1]
      if (!this.$v.causeSelected?.required) errors['causeSelected'] = messages[0]
      return errors
    }
  },
  apollo: {
    origins: {
      query: QUERY_GET_ORIGINS,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      result({ data }) {
        this.origins = data.origins.filter(origins => origins.active)
      }
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      /**
       * Update the model
       * @event input
       */
      this.$emit('input', this.dialog)
    },
    is_ambev_incentive() {
      if (this.is_ambev_incentive) this.is_ambev_participation = true
    },
    origin(oldValue) {
      if (oldValue) {
        this.sub_origin = ''
      }
    }
  },
  methods: {
    /**
     * Dates allowed in calendar display
     * @param {*} val
     * @return {Bollean}
     */
    allowedDates(val) {
      return allowedDateCrmActions(val)
    },
    /**
     * Function to close the modal
     */
    closeDialog() {
      /**
       * Close the dialog
       * @event closeDialog
       */
      this.$emit('closeDialog', this.dialog)
      this.$emit('input', false)
    },
    /**
     * Valida o input com vuelidate
     */
    validateForm() {
      this.$v.$reset()

      if (this.isSale) {
        this.$v.origin?.$touch()

        if (this.$v.$error || this.$v.origin.$error) {
          this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
          this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
          return false
        }

        if (typeof this.subOrigins === 'object' && this.subOrigins.length !== 0) {
          this.$v.sub_origin?.$touch()

          if (this.$v.$error || this.$v.sub_origin.$error) {
            this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
            this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
            return false
          }
        }
      }

      if (this.validateContractLink) {
        this.$v.contract_link?.$touch()

        if (this.is_ambev_incentive) {
          this.$v.ambev_incentive_months?.$touch()
          this.$v.ambev_incentive_text?.$touch()

          if (this.$v.$error || this.$v.ambev_incentive_months.$error || this.$v.ambev_incentive_text.$error) {
            this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
            this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
            return false
          }
        } else {
          this.$v.contract_link.$touch()
          if (this.$v.$error || this.$v.contract_link.$error) {
            this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
            this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
            return false
          }
        }
      }

      if (this.isDownsell) {
        this.$v.causeSelected?.$touch()

        if (this.$v.$error || this.$v.causeSelected.$error) {
          this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
          this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
          return false
        }
      }
      return true
    },
    getSubOriginValue(subOrigin) {
      return SUB_ORIGINS_ENUM_MAPPER[subOrigin] || ''
    },
    formatDate(date) {
      return `${date} ${DateTime.now().toFormat('HH:mm:ss')}`
    },
    save() {
      /**
       * Event executed on confirm button
       * @event confirm-action
       * @property {String} this.date
       */
      const isValid = this.validateForm()
      if (!isValid) return

      let result = {
        date: this.formatDate(this.date),
        observation: this.observation,
        is_ambev_participation: this.is_ambev_participation
      }

      if (this.hasBillingDate) result['billing_date'] = this.formatDate(this.billing_date)

      if (this.validateContractLink) {
        result['ambev_incentive'] = {
          is_ambev_incentive: this.is_ambev_incentive
        }
      }

      if (this.origin) result['origin'] = this.origin
      if (this.getSubOriginValue(this.sub_origin)) result['sub_origin'] = this.getSubOriginValue(this.sub_origin)

      if (this.contract_link) result['contract_link'] = this.contract_link
      if (this.is_ambev_incentive) {
        result['ambev_incentive']['ambev_incentive_months'] = parseInt(this.ambev_incentive_months)
        result['ambev_incentive']['ambev_incentive_text'] = this.ambev_incentive_text
      }
      if (this.isDownsell) {
        result.cause = this.causeSelected
        result.reason = this.reason
      }

      this.$emit('confirm-action', result)
    }
  },
  validations() {
    let result
    if (this.validateContractLink) {
      result = {
        contract_link: { required },
        origin: { required },
        ambev_incentive_months: { required, minValue: minValue(1) },
        ambev_incentive_text: { required }
      }
    } else {
      result = {
        contract_link: { required },
        origin: { required }
      }
    }
    if (typeof this.subOrigins === 'object' && this.subOrigins.length !== 0) {
      result['sub_origin'] = { required }
    }
    if (this.isDownsell) {
      result.causeSelected = { required }
    }
    return result
  }
}
</script>
